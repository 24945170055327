import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './css/NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow color-nav" style={{ zIndex: "1000", }} dark>
          <NavbarBrand tag={Link} to="/"><img className="logo" src=".\pics\Consilium Portal Logo.png" /></NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
            <NavItem>
                <NavLink tag={Link} className="text-light" onClick={this.toggleNavbar} to="/assignments">Timesheets</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-light" onClick={this.toggleNavbar} to="/profile">Profile</NavLink>
            </NavItem>
            <NavItem>
                <NavLink className="text-light" onClick={this.toggleNavbar} href="https://consiliumstaffing.com/search-jobs/" target="_blank">Search Jobs</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-light" onClick={this.toggleNavbar} to="/contact?rId=3&aId=2&lId=4">Contact Us</NavLink>
            </NavItem>

              <LoginMenu>
              </LoginMenu>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
