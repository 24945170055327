import React from 'react';
import TimesheetEntry3 from "./TimesheetEntry3";
import { useSearchParams, useLocation } from 'react-router-dom';

const OpenTimesheet3 = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    var tsId = "";
    var pId = "";
    var fName = "";

    if (searchParams.size > 0) {
        tsId = searchParams.get("id")
        pId = searchParams.get("pId")
        fName = searchParams.get("facility")
    }
    else {
        tsId = location.pathname.split("/")[2]
    }
    return (
        <>
            <TimesheetEntry3 tsid={tsId} />
        </>
        )
}
export default OpenTimesheet3;