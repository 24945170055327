import React, { Component } from 'react';
import ReactModal from 'react-modal'
import "./css/Modal.css"
import "./css/SignatureModal.css"


export class SignatureModal extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            lockScroll: false
        };
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal() {
        this.setState({ showModal: true, lockScroll: true });
        document.body.style.overflow = 'hidden';
    }

    handleCloseModal() {
        this.setState({ showModal: false, lockScroll: false });
        document.body.style.overflow = 'unset';
    }

    render() {
        //let error = this.props.totalHours !== 0 ? (
        //    <></>
        //) : (
        //        <>
        //            <span style={{ color: "red",}} >Your timesheet has no hours entered! If you worked, please click cancel and enter your shift times.</span><br /><br />
        //    </>
        //);

        let content =
            this.props.editable ?
                <>
                    
                </> :
                <>
                    <button id="save-submit" className="save-button" onClick={this.handleOpenModal}>Save and Submit</button>
                    <ReactModal
                        isOpen={this.state.showModal}
                        contentLabel="Minimal Modal Example"
                        className="content"
                        overlayClassName="overlay"
                        shouldFocusAfterRender={true}
                    >
                        <div className="modal-header">
                            <span>Submit { this.props.totalHours } Hours?</span>
                        </div>
                        <div className="modal-body">
                            {/*error*/}
                            <span>By submitting these hours, I attest that the hours submitted on this timesheet for this designated period ending are accurate and complete and were approved by an authorized representative of the Client Company.</span>
                            
                            
                        </div>
                        <div className="modal-footer" >
                            <button onClick={this.props.saveAndSubmit}>Submit</button>
                            <button onClick={this.handleCloseModal}>Cancel</button>
                        </div>

                    </ReactModal>
                </>;
        return (
            <div>
                {content}
            </div>
        );
    }
}