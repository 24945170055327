import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import Switch from './Switch.js'
import { Mixpanel } from './Mixpanel';
import './css/UserProfile.css';

export default class UserProfile extends Component {
    static displayName = UserProfile.name;


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            editing: false,
            isAuthenticated: false,
            providerId: null,
            profile: null

        };

    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();        
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated,user] = await Promise.all([authService.isAuthenticated(),authService.getUser()]);
        this.setState({
            isAuthenticated,
            providerId: user && user.sub
        });

        this.getProfileInfo(user.sub);
    }

    updateProfile(field, val) {
        var profileInfo = { ...this.state.profile }
        profileInfo[field] = val
        this.setState({profile: profileInfo})
    }


    //static renderTimesheetLines(lines) {
    //    return(            

    //    );
    //}

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p> :
            <>
                <div style={{ paddingBottom: "30px", }}>
                <div className="row profileRow">
                    <div style={{ null:null , }} className="col header">
                    <span><b>First Name</b></span><br />
                    <span>{this.state.profile.firstName}</span><br />
                </div>
                <div className="col header">
                    {this.state.editing ? (
                        <button
                            onClick={() => {
                                this.setState({ editing: false });
                                this.saveProfile();
                                Mixpanel.track("Profile Save");
                    }}
                        >
                            Save
                        </button>
                    ) : (
                        <button
                            onClick={() => {
                                this.setState({ editing: true });
                            }}
                        >
                            Update Contact Info
                        </button>
                    )}
                </div>
            </div>
            <div className="row profileRow">
                        <div className="col header">
                           <span><b>Last Name</b></span><br />
                           <span>{this.state.profile.lastName}</span><br />
                        </div>
                    </div>
                    <div className="row profileRow">
                        <div className="col header">
                            <span><b>Company/DBA</b></span><br />
                            <span>{this.state.profile.paymentInfo}</span><br />
                        </div>
                    </div>
                    <div className="row profileRow">
                        <div className="col header">
                            <span><b>Permanent Address(W-9)</b></span><br />
                            {this.state.profile.address1 !== null && this.state.profile.address1 !== "" ? (
                                <><span>{this.state.profile.address1}</span><br /></>) :
                                (<></>)
                            }
                            <span>{this.state.profile.address2}</span><br />
                            <span>{this.state.profile.city}, {this.state.profile.state} {this.state.profile.zip}</span><br />                           
                        </div>
                    </div>
                    <div className="row profileRow">
                        <div className="col header">
                            <span><b>Payment Address</b></span><br />
                            {this.state.editing ? (
                                <>
                                    <span>Address Line 1</span><br />
                                    <input
                                        type="text"
                                        defaultValue={this.state.profile.paymentAddress1}
                                        onChange={(e) => this.updateProfile("paymentAddress1", e.target.value)}
                                    /><br />
                                    <span>Address Line 2</span><br />
                                    <input
                                        type="text"
                                        defaultValue={this.state.profile.paymentAddress2}
                                        onChange={(e) => this.updateProfile("paymentAddress2", e.target.value)}
                                    /><br />
                                    <span>City</span><br />
                                    <input
                                        type="text"
                                        defaultValue={this.state.profile.paymentAddressCity}
                                        onChange={(e) => this.updateProfile("paymentAddressCity", e.target.value)}
                                    /><br />
                                    <span>State</span><br />
                                    <input
                                        type="text"
                                        defaultValue={this.state.profile.paymentAddressState}
                                        onChange={(e) => this.updateProfile("paymentAddressState", e.target.value)}
                                    /><br />
                                    <span>Zip</span><br />
                                    <input
                                        type="text"
                                        defaultValue={this.state.profile.paymentAddressZip}
                                        onChange={(e) => this.updateProfile("paymentAddressZip", e.target.value)}
                                    /><br />
                                </>
                            ) : (
                                <>
                                    <span>{this.state.profile.paymentAddress1}</span><br />
                                    {this.state.profile.paymentAddress2 !== null && this.state.profile.paymentAddress2 !== "" ? (
                                        <><span>{this.state.profile.paymentAddress2}</span><br /></>) :
                                        (<></>)
                                    }
                                    <span>{this.state.profile.paymentAddressCity}, {this.state.profile.paymentAddressState} {this.state.profile.paymentAddressZip}</span><br />
                                </>
                            )}
                            
                        </div>
                    </div>
                    <div className="row profileRow">
                        <div className="col header">
                            <span><b>Home Phone</b></span><br />
                            {this.state.editing ? (
                                <input
                                    type="tel"
                                    defaultValue={this.state.profile.homePhone}
                                    onChange={(e) => this.updateProfile("homePhone", e.target.value.replace(/\D/g, ''))}
                                />
                            ) : (
                                <>
                                        {this.state.profile.homePhone === null ? <><span><br /></span></> : (<><span>({this.state.profile.homePhone.toString().slice(0,3)}) {this.state.profile.homePhone.toString().slice(3, 6)}-{this.state.profile.homePhone.toString().slice(6, 10)}</span><br /></>)}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row profileRow">
                        <div className="col header">
                            <span><b>Mobile Phone</b></span><br />
                            {this.state.editing ? (
                                <input
                                    type="tel"
                                    defaultValue={this.state.profile.mobilePhone}
                                    onChange={(e) => this.updateProfile("mobilePhone", e.target.value.replace(/\D/g, ''))}
                                />

                            ) : (
                                <>
                                    {this.state.profile.mobilePhone === null ? <><span><br /></span></> : (<><span>({this.state.profile.mobilePhone.toString().slice(0, 3)}) {this.state.profile.mobilePhone.toString().slice(3, 6)}-{this.state.profile.mobilePhone.toString().slice(6, 10)}</span><br /></>)}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row profileRow">
                        <div className="col header">
                            <span><b>Email</b></span><br />
                            {this.state.editing ? (
                                <input
                                    type="text"
                                    defaultValue={this.state.profile.email}
                                    onChange={(e) => this.updateProfile("email", e.target.value)}
                                />

                            ) : (
                                <>
                                    <span>{this.state.profile.email}</span><br />
                                </>
                            )}
                        </div>
                </div>
                <div className="row profileRow">
                    <div className="col header">
                        <span><b>Receive Email Notifications</b><Switch id='email-toggle-switch' checked={this.state.profile.emailNotifications} handleChange={() => { this.updateProfile('emailNotifications', !this.state.profile.emailNotifications); this.saveProfile(); }}/></span>
                    </div>
                </div>
                <div className="row profileRow">
                    <div className="col header">
                        <span><b>Receive Text Notifications</b><Switch id='text-toggle-switch' checked={this.state.profile.textNotifications} handleChange={() => { this.updateProfile('textNotifications', !this.state.profile.textNotifications); this.saveProfile(); }} /></span>
                    </div>
                </div>
            </div>
                
        </>
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col header">
                            <h1 className="page-title"><svg className="svg-size" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "20px", }} width="100" height="100" viewBox="0 0 31.568 24.553">
                                <path id="address-card" d="M28.938,32H2.631A2.631,2.631,0,0,0,0,34.631V53.923a2.631,2.631,0,0,0,2.631,2.631H28.938a2.631,2.631,0,0,0,2.631-2.631V34.631A2.631,2.631,0,0,0,28.938,32Zm0,21.923H2.631V34.631H28.938ZM11.4,44.277a3.508,3.508,0,1,0-3.508-3.508A3.511,3.511,0,0,0,11.4,44.277ZM6.489,51.292H16.31a1.149,1.149,0,0,0,1.228-1.052V49.187a3.451,3.451,0,0,0-3.683-3.157,13.232,13.232,0,0,1-2.455.438,13.04,13.04,0,0,1-2.455-.438,3.451,3.451,0,0,0-3.683,3.157V50.24A1.149,1.149,0,0,0,6.489,51.292ZM19.73,47.784h6.138a.44.44,0,0,0,.438-.438v-.877a.44.44,0,0,0-.438-.438H19.73a.44.44,0,0,0-.438.438v.877A.44.44,0,0,0,19.73,47.784Zm0-3.508h6.138a.44.44,0,0,0,.438-.438v-.877a.44.44,0,0,0-.438-.438H19.73a.44.44,0,0,0-.438.438v.877A.44.44,0,0,0,19.73,44.277Zm0-3.508h6.138a.44.44,0,0,0,.438-.438v-.877a.44.44,0,0,0-.438-.438H19.73a.44.44,0,0,0-.438.438v.877A.44.44,0,0,0,19.73,40.769Z" transform="translate(0 -32)" fill="#11294c" />
                            </svg>PROFILE</h1>

                        </div>
                    </div>
                    {contents}
                    
                </div>
                
            </div>
        );

    }
    async getProfileInfo(pId) {
        const profToken = await authService.getAccessToken();
        const tsResponse = await fetch("api/userprofile/" + pId, {
            headers: !profToken ? {} : { 'Authorization': `Bearer ${profToken}` }
        });
        const tsData = await tsResponse.json();
        this.setState({
            profile: tsData,
            loading: false
        });
    }

    saveProfile = async () => {
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'PUT',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(this.state.profile)
        };
        const response = await fetch('api/userprofile/' + this.state.profile.id, requestOptions);
        //const data = response.json();
        //console.log(data);

    }
}
