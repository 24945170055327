import { useState, useEffect } from "react";
import DragDrop from "./DragDrop";
import ExpenseFileList from "./ExpenseFileList"
import "./css/Expenses.css"

export default function ExpenseUpload({timesheetId, token}) {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        let ignore = false;
        if (token !== '') { 
            fetch("api/file/GetFiles/" + timesheetId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(result => {
                if (!ignore) {
                    return result.json();
                }
            }).then(data => {
                if (JSON.stringify(data) !== JSON.stringify(files)) {
                    setFiles(data);
                }
                return () => {
                    ignore = true;
                }
            });
            
        }
    }, [timesheetId, token, files]);

    return <>
        <h1 className="expense-title expense-header">Expenses</h1>
                    
        <DragDrop files={files} setFiles={setFiles} timesheetId={timesheetId} />
        <div className="expenses">
            <ExpenseFileList files={files} setFiles={setFiles} token={token} timesheetId={timesheetId} />
        </div>
        </>
}
