import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import './css/Contacts.css';

export default class Contacts extends Component {
    static displayName = Contacts.name;

    constructor(props) {
        super(props);

        this.state = { assignment: null, recruiter: null, accountManager: null, logistics: null, profile: null, rId: null, aId: null, lId: null, isAuthenticated: false, userName: '', loading: true };

    }
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState(
            {
                userName: user && user.sub
            },
            () => { this.getConsiliumContacts(user.sub) }
        );
        
    }


    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : <div className="cards">
                <div className="card">
                    <div className="pic">
                        <img src={this.state.accountManager.picturePath} alt="Recruiter"></img>
                    </div>
                    <div className="text">
                        <span className="name">
                            <strong>{this.state.accountManager.name}</strong><br />
                        </span>
                        <span className="title">
                            {this.state.accountManager.role}<br />
                        </span>
                        <span className="phone">
                            Direct: ({this.state.accountManager.phone.toString().split('').slice(0, 3)}) {this.state.accountManager.phone.toString().split('').slice(3, 6)}-{this.state.accountManager.phone.toString().split('').slice(6, 10)}<br />
                        </span>
                        <span className="email">
                            <a href={"mailto:" + this.state.accountManager.email}>{this.state.accountManager.email}</a><br />
                        </span>
                    </div>
                </div>
                <div className="card">
                    <div className="pic">
                        <img src={this.state.logistics.picturePath} alt="Recruiter"></img>
                    </div>
                    <div className="text">
                        <span className="name">
                            <strong>{this.state.logistics.name}</strong><br />
                        </span>
                        <span className="title">
                            {this.state.logistics.role}<br />
                        </span>
                        <span className="phone">
                            Direct: ({this.state.logistics.phone.toString().split('').slice(0, 3)}) {this.state.logistics.phone.toString().split('').slice(3, 6)}-{this.state.logistics.phone.toString().split('').slice(6, 10)}<br />
                        </span>
                        <span className="email">
                            <a href={"mailto:" + this.state.logistics.email}>{this.state.logistics.email}</a><br />
                        </span>
                    </div>
                </div>                
                <div className="card">
                    <div className="pic">
                        <img src={this.state.recruiter.picturePath} alt="Recruiter"></img>
                    </div>
                    <div className="text">
                        <span className="name">
                            <strong>{this.state.recruiter.name}</strong><br />
                        </span>
                        <span className="title">
                            {this.state.recruiter.role}<br />
                        </span>
                        <span className="phone">
                            Direct: ({this.state.recruiter.phone.toString().split('').slice(0, 3)}) {this.state.recruiter.phone.toString().split('').slice(3, 6)}-{this.state.recruiter.phone.toString().split('').slice(6, 10)}<br />
                        </span>
                        <span className="email">
                            <a href={"mailto:" + this.state.recruiter.email}>{this.state.recruiter.email}</a><br />
                        </span>
                    </div>
                </div>
            </div>
        return (
            <div>
                <div className="container">
                    <div className="header">
                        <h1 style={{ textAlign: 'center', }}>Contacts</h1>
                    </div>
                    <div className="header">
                        <h4 style={{ textAlign: 'center', }}>Meet Your Team</h4>
                    </div>
                    <div className="col">
                        <p className="description"> We are dedicated to making your Locums experience the best in the industry. Below is your team for your current assignment. You can reachout to them if you have any questions.</p>
                    </div>
                    {contents}
                   
                </div>
            </div>
        );
    }

    
    async getConsiliumProfileInfo(rId, aId, lId) {
        const token = await authService.getAccessToken();
        const profileResponse = await Promise.all([
            fetch("api/consiliumprofile/" + rId, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } }),
            fetch("api/consiliumprofile/" + aId, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } }),
            fetch("api/consiliumprofile/" + lId, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } }),
        ])
            // map array of responses into an array of response.json() to read their content
            .then(responses => Promise.all(responses.map(r => r.json())))
            // all JSON answers are parsed: "users" is the array of them
            .then(users => this.setState({
                recruiter: users[0],
                accountManager: users[1],
                logistics: users[2],
                loading: false }));


        //this.setState({
        //    recruiter: await tsResponse[0].json(),
        //    accountManager: await tsResponse[1].json(),
        //    logistics: await tsResponse[2].json(),
        //    loading: false
        //});
    }

    async getConsiliumContacts(id) {
        const token = await authService.getAccessToken();
        const tsResponse = await fetch("api/assignment/GetByProviderID/" + this.state.userName, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const tsData = await tsResponse.json();
        //tsData.forEach(assignment => assignment.expectedEndDate = new Date(assignment.expectedEndDate).toLocaleDateString());        
        this.setState(
            {
                rId: tsData[0].recruiterProfileId,
                aId: tsData[0].accountManagerProfileId,
                lId: tsData[0].logisticsProfileId                
            },
            () => { this.getConsiliumProfileInfo(this.state.rId, this.state.aId, this.state.lId) }
                    
        );
        
    }
}