import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import OpenTimesheet3 from "./components/OpenTimesheet3";
import { Home } from "./components/Home";
import UserProfile from "./components/UserProfile";
import GetContacts from "./components/GetContacts";
import GetTimesheets from "./components/GetTimesheets";
import { Assignments } from "./components/Assignments";

const AppRoutes = [
  {
    index: true,
    requireAuth: true,
    element: <Home />
  },
  {
      path: '/assignments/',
    requireAuth: true,
    element: <Assignments />
  },
  {
    path: '/timesheet-entry/:id',
    requireAuth: true,
      element: <OpenTimesheet3 />
  },
  {
    path: '/timesheets',
    requireAuth: true,
    element: <GetTimesheets />
  },
  {
    path: '/profile',
    requireAuth: true,
    element: <UserProfile />
  },
  {
    path: '/contact',
    requireAuth: true,
    element: <GetContacts />
    },
    ...ApiAuthorizationRoutes
];

export default AppRoutes;
