import React, { Component } from 'react';
import { TimesheetDate3 } from './TimesheetDate3';
import { SignatureModal } from './SignatureModal';
import { NotificationManager } from 'react-notifications';
import ExpenseDownload from './ExpenseDownload';
import authService from './api-authorization/AuthorizeService';
import moment from "moment";
import { Mixpanel } from "./Mixpanel";
import "./css/TimesheetEntry3.css"
import 'react-notifications/lib/notifications.css';
import DragDrop from "./DragDrop";
import { Link } from "react-router";
import ExpenseUpload from './ExpenseUpload';

export default class TimesheetEntry3 extends Component {
    static displayName = TimesheetEntry3.name;    

    constructor(props) {
        super(props);

        this.state = { tsid: props.tsid, tsData: [], expenseFileArr: [], totalHours: 0, providerName: "", degree: "", days: [], loading: true, tsLoading: true, wrongTS: false, token: "" };
    }

    async componentDidMount() {
        var profile = await authService.getUser();
        const getToken = await authService.getAccessToken();
        this.setState({ providerId: profile['sub'], token: getToken });
        await this.getTimesheetInfo(this.state.tsid);
        await this.getTimesheetLines(this.state.tsid);
        //await this.getProviderName();
        
    }


    render() {
        let save = !this.state.tsLoading && this.state.tsData.editable ? (
            <><div className="buttons save-buttons">
                <div className="button-cell">
                    <button id="save" className="save-button" onClick={this.saveTimesheet}>Save</button>
                </div>
                <div className="button-cell">
                    <SignatureModal key={this.state.tsid} saveAndSubmit={this.saveAndSubmit} totalHours={this.state.totalHours} />
                </div>
            </div></>) : (
                <>
                    
            </>)
        let contents = <></>
        if (this.state.wrongTS) {
            contents = <>This timesheet does not belong to you. Please go back to your timesheet list to access your own timesheets </>
        }
        else {
            contents = this.state.loading || this.state.tsLoading
                ? <p><em>Opening Timesheet...</em></p>
                : <>
                    {Object.values(this.state.days).map((day, i) =>
                        <>
                            <div key={"date" + i} className="date-sm">&nbsp;&nbsp;{moment(day[0].date).format("ddd MM/DD")}</div>
                            <div className="timesheet-date">
                                <TimesheetDate3 key={'td-' + day[0].date} index={i} lines={day} updateDay={this.updateDay} status={this.state.status} editable={this.state.tsData.editable} updateTotalTimesheetHours={this.updateTotalTimesheetHours} />
                            </div>
                        </>
                    )}

                </>
        }
        return (
            <div className="grid-container">                
                <div className="timesheet-header">
                    <h1><svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "20px", }} width="36" height="48" viewBox="0 0 24 32">
                        <path id="file-alt" d="M18,15.5v1.75a.752.752,0,0,1-.75.75H6.75A.752.752,0,0,1,6,17.25V15.5a.752.752,0,0,1,.75-.75h10.5A.752.752,0,0,1,18,15.5ZM17.25,20H6.75a.752.752,0,0,0-.75.75V22.5a.752.752,0,0,0,.75.75h10.5A.752.752,0,0,0,18,22.5V20.75A.752.752,0,0,0,17.25,20ZM24,8.244V29a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H15.756a3,3,0,0,1,2.119.881l5.244,5.244A2.989,2.989,0,0,1,24,8.244Zm-8-5V8h4.756L16,3.244ZM21,29V11H14.5A1.5,1.5,0,0,1,13,9.5V3H3V29Z" fill="#11294c" />
                    </svg>TIMESHEET</h1>
                </div>                
                
                <div className="timesheet-details">
                    <div>
                        <div className="left-detail">
                            <h5>Provider: <br /> <span style={{ fontWeight: "normal", }}> {this.state.tsData.degree === "MD" || this.state.tsData.degree === "DO" ? "Dr." : ("")} {this.state.tsData.firstName} {this.state.tsData.lastName}</span></h5>
                        </div>                                               
                    </div>
                    <div>
                        <div className="right-detail">
                            <h5>Pay Period: <br />  <span style={{ fontWeight: "normal", }}> {this.state.tsData === null ? '' : this.state.tsData.payPeriod}</span></h5>
                            <h6 style={{ fontWeight: "normal", color: "red",}}>Timesheet Due {this.state.tsData === null ? '' : moment(this.state.tsData.dueDate).format("MM/DD")}</h6>
                        </div>                                          
                    </div> 
                    <div>
                        <div className="left-detail">
                            <h5>Facility: <br />  <span style={{ fontWeight: "normal", }}> {this.state.tsData.facilityName}</span></h5>
                        </div>  
                         
                    </div>
                    <div>
                        <div className="right-detail">
                            <h5>Total Hours Worked: <br />  <span style={{ fontWeight: "normal", }}> {this.state.totalHours} Hours</span></h5>
                            
                        </div>                      
                    </div>  
                    
                </div>
                
            
                <div className="timesheet">
                    <div className="timesheet-date">
                        <div className="timesheet-header-cells">
                            <div className="desktopShow"><span className="header-values">Date</span></div>
                            <div className="desktopShow"><span className="header-values">Shift Start</span></div>
                            <div className="desktopShow"><span className="header-values">Meal/Break Start</span></div>
                            <div className="desktopShow"><span className="header-values">Meal/Break End</span></div>
                            <div className="desktopShow"><span className="header-values">Shift End</span></div>
                            <div className="desktopShow"><span className="header-values">Total Hours</span></div>
                            <div className="desktopShow"><span className="header-values">On Call</span></div>
                            <div className="desktopShow"><span className="header-values">Call Back Hours</span></div>
                            <div className="desktopShow"><span className="header-values">Notes & Comments</span></div>
                        </div>
                    </div>
                    {contents}
                    
                </div>
                {save}
                <ExpenseUpload timesheetId={this.state.tsid} token={this.state.token } />
            </div >

        );
    
    }

    async getTimesheetInfo(tId) {
        const token = await authService.getAccessToken();
        const tsResponse = await fetch("api/timesheet/GetBasicInfo/" + tId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        
        const tsData = await tsResponse.json();
        if (tsData.providerId === this.state.providerId || this.state.providerId === '869cef5d-121d-4507-a9c4-75bcefb060d1') {
            this.setState({ tsData: tsData, tsLoading: false, wrongTS: false });
        }
        else {
            this.setState({ tsData: [], tsLoading: false, wrongTS: true });
        }
    }
    async getTimesheetLines(lId) {
        if (!this.state.wrongTS) {
            const token = await authService.getAccessToken();
            const tslResponse = await fetch("api/timesheetline/tsid?id=" + lId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const tslData = await tslResponse.json();
            var days = this.groupBy(tslData, 'date')
            var totalHours = 0;
            if (!this.state.wrongTS) {
                tslData.forEach(line => {
                    totalHours += line.totalHours;
                });
            }
        
            this.setState({ days: days, totalHours: totalHours.toFixed(2), loading: false });
        }
    }

    updateDay = (index, lineUpdates) => {
        var newDays = this.state.days;
        newDays[index] = lineUpdates;
        this.setState({ days: newDays });
    }

    saveTimesheet = () => {
        try {
            var error = false;
            this.state.days.every(day => {
                day.every(line => {
                    if (line.error === true) {
                        error = true;
                        return false;
                    }
                    return true;
                });
            });
            if (error !== true) {

                var totalHours = 0.0;
                this.state.days.forEach(day => {
                    day.forEach(async line => {
                        totalHours += line.totalHours;

                        const token = await authService.getAccessToken();
                        if (line.Changed === true) {
                            if (line.shiftStart !== null && line.shiftStart.toString().length === 5) { line.shiftStart += ':00'; } else if(line.shiftStart === ""){ line.shiftStart = null }
                            if (line.shiftEnd !== null && line.shiftEnd.toString().length === 5) { line.shiftEnd += ':00'; } else if (line.shiftEnd === "") { line.shiftEnd = null }
                            if (line.mealOut !== null && line.mealOut.toString().length === 5) { line.mealOut += ':00'; } else if (line.mealOut === "") { line.mealOut = null }
                            if (line.mealIn !== null && line.mealIn.toString().length === 5) { line.mealIn += ':00'; } else if (line.mealIn === "") { line.mealIn = null }

                            const requestOptions = {
                                method: 'PUT',
                                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                                body: JSON.stringify(line),

                            };
                            await fetch('api/timesheetline/' + line.id, requestOptions);
                        }
                    });
                });
                //Mixpanel.track("Timesheet Save")
                NotificationManager.success('Timesheet Saved Successfully', 'Saved!', 3000);                
                this.setState({ totalHours: totalHours.toFixed(2) });
            }
            else {
                NotificationManager.error("Please fix errors before saving or submitting", "Error!", 3000)
            }
            return error;
        }
        catch {
            // Add this line where you want to show the notification
            NotificationManager.error('Issue saving timesheet. Please wait a moment and try to save again', 'Error!', 6000);
        }
    }

    saveAndSubmit = async () => {
        try {
            var error = this.saveTimesheet();
            if (error) {
                return false;
            }
            var ts = { ...this.state.tsData };
            delete ts.firstName;
            delete ts.lastName;
            delete ts.degree;
            delete ts.facilityName;

            ts.status = 'Submitted';
            ts.editable = false;
            ts.dateSubmitted = new Date().toISOString().slice(0, 19);
            
            const token = await authService.getAccessToken();
            

            const requestOptions = {
                method: 'PUT',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(ts),

            };
            var resp = await fetch('api/timesheet/' + this.state.tsData.id, requestOptions);
            if (resp.status === 204) {
                var tempData = this.state.tsData;
                tempData.status = ts.status;
                tempData.editable = ts.editable;
                tempData.dateSubmitted = ts.dateSubmitted;
                this.setState({ tsData: tempData });
                Mixpanel.track("Timesheet Submit")
                window.location.reload(true);
            }
            else {
                NotificationManager.error('Issue submitting timesheet. Please wait a moment and try to submit again', 'Error!', 6000);
            }
        }
        catch (e) {
            NotificationManager.error('Issue submitting timesheet. Please wait a moment and try to submit again', 'Error!', 6000);
        }
        
    }

    updateTotalTimesheetHours = (hours) => {
        this.setState({ totalHours: (parseFloat(this.state.totalHours) + hours).toFixed(2) });
    }
    groupBy(data, key) {
        let prevKey = data[0][key];
        let arr = [];
        return data.reduce((acc, cur, i) => {

            if (prevKey === cur[key]) {
                arr.push(cur);
            }
            else {
                prevKey = cur[key];
                acc.push(arr);
                arr = [];
                arr.push(cur);
            }

            if (i === data.length - 1) {
                acc.push(arr);
            }

            return acc;
        }, [])
    } 


    //api call to grab all files with current ts id and save to state
    





    //addLine = async (date) => {

    //    let line = { date: date, timesheetId: this.state.lines[0].timesheetId, totalHours: 0, updated: false, extraShift: true }
    //    const token2 = await authService.getAccessToken();
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: !token2 ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token2}` },
    //        body: JSON.stringify(line),

    //    };
    //    var r = await fetch('api/timesheetline', requestOptions);
    //    var data = await r.json();
    //    line['id'] = data['id'];
    //    var newLines = this.state.lines;
    //    newLines.push(line);
    //    this.setState({ lines: newLines });
    //    this.forceUpdate();
    //}

    //updateLines(newLines) {
    //    this.setState({ lines: newLines });
    //}

    //test = async (date) => {
    //    let line = { date: date, timesheetId: this.state.lines[0].timesheetId, updated: false }
    //    const token2 = await authService.getAccessToken();
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: !token2 ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token2}` },
    //        body: JSON.stringify(line),

    //    };
    //    await fetch('api/timesheetline', requestOptions);
    //}


}
