import React, { Component } from 'react';
import { TimesheetList } from "./TimesheetList";
import authService from './api-authorization/AuthorizeService';
import './css/Assignments.css';

export class Assignments extends Component {
    static displayName = Assignments.name;


    constructor(props) {
        super(props);

        this.state = { assignments: [], isAuthenticated: false, userName: '', authChecked: false, assignmentsChecked: false, loading: true };

    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.sub,
            authChecked: true
        });
    }


    render() {
        const { isAuthenticated, userName } = this.state;
        if (!this.state.authChecked) { return (<div><span>loading</span></div>); }
        else if (!isAuthenticated) {
            return (<div><span>not authenticated</span></div>);
        }
        else
        {
            if (this.state.assignments.length === 0 && !this.state.assignmentsChecked) {
                this.getAssignments();
            }

            let content = this.state.assignmentsChecked && this.state.assignments.length > 0 ? (
                <>
                    {this.state.assignments.map((assignment, i) =>
                        <>
                            <div className="row" style={{ marginTop: "50px", }}>
                                <div className="col col-lg-3">
                                    <h5>Facility: {assignment.facilityName}</h5>
                                </div>
                                <div className="col col-lg-3">
                                    <h5>Status: <span>{assignment.status}</span></h5>
                                </div>
                                <div className="col col-lg-3">
                                    <h5>Expected End Date: <span>{assignment.expectedEndDate}</span></h5>
                                </div>
                            </div>
                            <TimesheetList key={assignment.id} aid={assignment.id} facilityName={assignment.facilityName} />
                        </>
                    )}
                </>
            ) : (
                    <>
                        <h5>No Active Assignments Found</h5>
                    </>
            );

            return (
                <div className="container">
                    <div className="row">
                        <div className="content-header">
                            <h1>TIMESHEETS</h1>
                        </div>
                    </div>                    

                    {content}

                </div>
            );
        }
    }

    async getAssignments() {
        const token = await authService.getAccessToken();
        const tsResponse = await fetch("api/assignment/GetByProviderID/" + this.state.userName, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const tsData = await tsResponse.json();
        tsData.forEach(assignment => assignment.expectedEndDate = new Date(assignment.expectedEndDate).toLocaleDateString());
        this.setState({ assignments: tsData, assignmentsChecked:true, loading: false });
    }
}
