import { useState } from "react";
import ExpenseFileDeleteModal from "./ExpenseFileDeleteModal";
import ExpenseDownloadLink from "./ExpenseDownloadLink";

export default function ExpenseFileLine({ file, deleteFile }) {

    return <>
        <div className="expense-line">
            <div className="expense-items"><ExpenseDownloadLink file={file} /></div>
            <div> <ExpenseFileDeleteModal deleteFile={deleteFile} file={file} /> </div>
        </div>
    </>
}

