import React, { Component } from 'react'
import authService from './api-authorization/AuthorizeService'
import moment from "moment"
import TimePicker from 'react-time-picker';
import { NoteModal } from './NoteModal'
import "./css/TimesheetLine3.css"

export class TimesheetLine3 extends Component {
    static displayName = TimesheetLine3.name;    

    constructor(props) {
        super(props);
        this.state = { line: this.props.line };
    }
    
    

    clearLine = () => {
        var newLine2 = this.state.line;
        newLine2.shiftStart = "";
        newLine2.shiftEnd = "";
        newLine2.mealOut = "";
        newLine2.mealIn = "";
        newLine2.onCall = false;
        newLine2.callBackHours = null;
        newLine2.notes = "";
        newLine2.Changed = true;
        this.setState({ line: newLine2 });
        this.updateTotalHours()
    }

    ConvertTimeStrToDec(timeStr) {
        var hoursMinutes = timeStr.split(/[.:]/);
        var hours = parseInt(hoursMinutes[0]);
        console.log(hours)
        var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) / 60 : 0;
        console.log(minutes)
        return hours + minutes;
    }

    update = (field, value) => {
        var newLine = this.state.line;
        if (field === "shiftStart" || field === "shiftEnd" || field === "mealOut" || field === "mealIn") {
            this.UpdateTotalHours(newLine, field, value);
        }
    }

    updateTotalHours() {
        var end = null;
        var start = null;
        var min = null;
        var mout = null;


        if (this.state.line.shiftEnd != null && this.state.line.shiftEnd != "") {
            end = this.ConvertTimeStrToDec(this.state.line.shiftEnd);
        }

        if (this.state.line.shiftStart != null && this.state.line.shiftStart != "") {
            start = this.ConvertTimeStrToDec(this.state.line.shiftStart);
        }

        if (this.state.line.mealIn != null && this.state.line.mealIn != "") {
            min = this.ConvertTimeStrToDec(this.state.line.mealIn);
        }

        if (this.state.line.mealOut != null && this.state.line.mealOut != "") {
            mout = this.ConvertTimeStrToDec(this.state.line.mealOut);
        }

        if (mout != null && mout < start) {
            mout += 24;
            if (min != null) { min += 24; }
            if (end != null) { end += 24; }

        }
        else if (min != null && min < mout) {
            min += 24;
            if (end != null) { end += 24; }
        }
        else if (end != null && end < min) {
            end += 24;
        }
        else if (end != null && end <= start) {
            end += 24
        }


        var tempLine = this.state.line;
        var initialHourCount = 0;
        if (this.state.line.totalHours != null) {
            initialHourCount = this.state.line.totalHours;
        }
        
        if (end === null || isNaN(end) || start === null || isNaN(start)) {
            tempLine['totalHours'] = 0;
        }
        else if (mout === null || isNaN(mout) || min === null || isNaN(min)) {
            tempLine['totalHours'] = parseFloat((end - start).toFixed(2));     
            tempLine['error'] = false;
            this.props.updateError(false);
        }
        else {            
            tempLine['totalHours'] = parseFloat((end - min + mout - start).toFixed(2));   
            if (tempLine['totalHours'] < 0.0) {
                tempLine['error'] = true;
                this.props.updateError(true);
            }
            else {
                tempLine['error'] = false;
                this.props.updateError(false);
            }
            
        }
        this.props.updateTotalTimesheetHours(tempLine['totalHours'] - parseFloat(initialHourCount));
        this.setState({ line: tempLine });
    };

    delete = async (id) => {
        const token2 = await authService.getAccessToken();
        const requestOptions = {
            method: 'DELETE',
            headers: !token2 ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token2}` },

        };
        await fetch('api/timesheetline/' + id, requestOptions);
        this.props.deleteLine(this.props.id);
        this.forceUpdate();
    }

    handleChange = (e) => {
        var tempLine = this.state.line;
        if (e.target.id == 'onCall') {
            tempLine[e.target.id] = e.target.checked;
        }
        else if (e.target.id == 'callBackHours') {
            tempLine[e.target.id] = parseFloat(e.target.value);
        }
        else {
            tempLine[e.target.id] = e.target.value;
        }
        tempLine['Changed'] = true;
        this.setState({ line: tempLine });
    }

    checkIfEnterPressed = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    }

    render() {

        let deleteButton = this.props.order !== 0 ? <img className="delete-button2" style={{ marginLeft: "30px", }} src="../pics/x-icon.png" height="25px" width="25px" title="Delete Shift" alt="icon to delete extra shift" onClick={() => this.delete(this.props.id)} /> : <img src="../pics/plus-icon.png" height="25px" width="25px" title="Add Shift" alt="icon to add extra shift" style={{ margin: "0px 0px 3px 10px", cursor: "pointer", }} onClick={() => this.props.addLine(this.props.date)} />
        let clearButton = <img className="delete-button2" style={{ marginLeft: "30px", }} src="../pics/clean-icon-white.png" height="25px" width="25px" title="Clear Shift" alt="icon to clear shift" onClick={this.clearLine} />
        let date = this.props.order !== 0 ? <span style={{ fontSize: '15px', }}>Shift {this.props.order + 1}</span> : <span className="date">{moment(this.props.date).format("ddd MM/DD")}</span>
        let lines = this.props.editable ? (
            <>
                <div className="timesheetCol2 right date-lg">
                    <div className="date-lg">
                        {date}
                        {deleteButton}
                    </div>
                </div> 

                <div className="timesheetCol2">
                    <input id="shiftStart" className="timesheetField" type="time" max="23" step="60" value={this.state.line.shiftStart} onChange={(e) => this.handleChange(e)} onBlur={(e) => this.updateTotalHours()} onKeyUp={(e) => this.checkIfEnterPressed(e) } /><br /><label className="mobileShow">Shift Start</label>
                </div>


                <div className="timesheetCol2">
                    <input id="mealOut" className={this.state.line.totalHours < 0.0 ? "timesheetField line-error" : "timesheetField"} type="time" value={this.state.line.mealOut} onChange={(e) => this.handleChange(e)} onBlur={(e) => this.updateTotalHours()} onKeyUp={(e) => this.checkIfEnterPressed(e)} /><br /><label className="mobileShow">Meal/Break Start</label>
                </div>

                <div className="timesheetCol2">
                    <input id="mealIn" className={this.state.line.totalHours < 0.0 ? "timesheetField line-error" : "timesheetField"} type="time" value={this.state.line.mealIn !== null ? this.state.line.mealIn : null} onChange={(e) => this.handleChange(e)} onBlur={(e) => this.updateTotalHours()} onKeyUp={(e) => this.checkIfEnterPressed(e)} /><br /><label className="mobileShow">Meal/Break End</label>
                </div>


                <div className="timesheetCol2">
                    <input id="shiftEnd" className="timesheetField" type="time" value={this.state.line.shiftEnd !== null ? this.state.line.shiftEnd : null} onChange={(e) => this.handleChange(e)} onBlur={(e) => this.updateTotalHours()} onKeyUp={(e) => this.checkIfEnterPressed(e)} /><br /><label className="mobileShow">Shift End</label>
                </div>


                <div className="timesheetCol2">
                    <span id="totalHours" className="totalHours">{this.state.line.totalHours}</span><br /><label className="mobileShow">Total Hours</label>
                </div>


                <div className="timesheetCol2">
                    <input id="onCall" className="checkbox" type="checkbox" onChange={(e) => this.handleChange(e)} checked={this.state.line.onCall} /><br /><label className="mobileShow">On Call</label>
                </div>


                <div className="timesheetCol2">
                    <input id="callBackHours" className="timesheetField" type="number" onChange={(e) => this.handleChange(e)} step="any" min="0.0" max="24.0" value={this.state.line.callBackHours} onKeyUp={(e) => this.checkIfEnterPressed(e)} /><br /><label className="mobileShow">Call Back <br />Hours</label>
                </div>


                <div className="timesheetCol2">
                    <NoteModal key={this.state.line.id} handleChange={this.handleChange} notes={this.state.line.notes} editable={true} date={moment(this.props.date).format("ddd MM/DD")} />
                </div>

                
                <div className="timesheetCol2 clear-button mobileShow">
                    <button style={{ fontSize: '16px', }} onClick={this.clearLine}>Clear Shift</button>
                </div>
                
                 
            </>
        ) : (
                <>
                    <div className="timesheetCol2 right date-lg">
                        <div className="date-lg">
                            {date}
                        </div>
                    </div>
                    <div className="timesheetCol2">
                        <span className="timesheetField">{this.state.line.shiftStart !== null ? moment(this.state.line.shiftStart, 'HH:mm:ss').format('h:mm A') : ""}</span><br /><label className="mobileShow">Shift Start</label>
                    </div>


                    <div className="timesheetCol2">
                        <span className="timesheetField">{this.state.line.mealOut !== null ? moment(this.state.line.mealOut, 'HH:mm:ss').format('h:mm A') : ""}</span><br /><label className="mobileShow">Meal/Break Start</label>
                    </div>

                    <div className="timesheetCol2">
                        <span className="timesheetField">{this.state.line.mealIn !== null ? moment(this.state.line.mealIn, 'HH:mm:ss').format('h:mm A') : ""}</span><br /><label className="mobileShow">Meal/Break End</label>
                    </div>


                    <div className="timesheetCol2">
                        <span className="timesheetField">{this.state.line.shiftEnd !== null ? moment(this.state.line.shiftEnd, 'HH:mm:ss').format('h:mm A') : ""}</span><br /><label className="mobileShow">Shift End</label>
                    </div>


                    <div className="timesheetCol2">
                        <span className="totalHours">{this.state.line.totalHours}</span><br /><label className="mobileShow">Total Hours</label>
                    </div>


                    <div className="timesheetCol2">
                        {this.state.line.onCall === true ? <input className="checkbox" type="checkbox" checked readonly /> : <input className="checkbox" type="checkbox" checked={false} readonly />}<br /><label className="mobileShow">On Call</label>
                    </div>


                    <div className="timesheetCol2">
                        <span className="timesheetField" >{this.state.line.callBackHours} </span><br /><label className="mobileShow">Call Back Hours</label>
                    </div>


                    <div className="timesheetCol2">
                        {this.state.line.notes ? <><NoteModal key={this.state.line.id} handleChange={this.handleChange} notes={this.state.line.notes} editable={false} date={moment(this.props.date).format("ddd MM/DD")} /></> :
                            <></>}
                    </div>   
                </>
                );
        return (
            <>     
                {lines}
                
            </>
        )
    }
}
