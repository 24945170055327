import React, { Component } from 'react';
import ReactModal from 'react-modal'
import "./css/Modal.css"
import "./css/NoteModal.css"

export class NoteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            date: this.props.date
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    render() {
        let content = 
            this.props.editable ? 
                <>
                    <button style={{ fontSize: '16px', marginTop: '-10px', }} onClick={this.handleOpenModal}>Edit Note</button>
                    <ReactModal
                        isOpen={this.state.showModal}
                        contentLabel="Minimal Modal Example"
                        className="content"
                        overlayClassName="note-overlay"
                        shouldFocusAfterRender={true}
                        shouldCloseOnOverlayClick={true}
                    >
                        <div className="modal-header">
                            &nbsp;&nbsp;Edit Note: {this.state.date}
                        </div>
                        <div className="modal-body-note">
                            <textarea id="notes" className="noteBody" rows="3" onChange={(e) => this.props.handleChange(e)} value={this.props.notes} />
                        </div>
                        <div className="modal-footer">
                            <button className="noteClose"onClick={this.handleCloseModal}>Close Note</button>
                        </div>                  
                    
                    </ReactModal>
                </> :
                <>
                    <button style={{ fontSize: '16px', marginTop: '-10px', }} onClick={this.handleOpenModal}>View Note</button>
                    <ReactModal
                        isOpen={this.state.showModal}
                        contentLabel="Minimal Modal Example"
                        className="content"
                        overlayClassName="note-overlay"
                    >
                        <div className="modal-header">
                            &nbsp;&nbsp;View Note: {this.state.date}
                        </div>
                        <div className="modal-body">
                            <span>{this.props.notes}</span><br />
                        </div>
                        <div className="modal-footer">
                            <button className="noteClose" onClick={this.handleCloseModal}>Close Note</button>
                        </div>

                    </ReactModal>
                </>;
        return (
            <div>
                {content}
            </div>
        );
    }
}