import React from 'react';
import './css/Switch.css';

const Switch = ({ id, checked, handleChange }) => {

    return (
        <>
            <input
                checked={checked}
                onChange={handleChange}
                className="react-switch-checkbox"
                id={id}
                type="checkbox"
            />
            <label
                className="react-switch-label"
                htmlFor={id}
            >
                <span className={`react-switch-button`} />
            </label>
        </>
    );
};

export default Switch;