import React from 'react';
import Contacts from "./Contacts";
import { useParams, useSearchParams } from 'react-router-dom';

const GetContacts = (props) => {
    const id = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <>
            <Contacts />
        </>
    )
}
export default GetContacts;