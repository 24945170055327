import mixpanel from 'mixpanel-browser';

mixpanel.init('3fa04d23fd300c3d0a9bf1bd31054e7d', { debug: true, track_pageview: true, persistence: 'localStorage' });

let env_check = true;

let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
    },
};

export let Mixpanel = actions;