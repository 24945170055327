import React, { Component } from 'react';
import {NotificationContainer } from 'react-notifications'
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Footer from './Footer'
import "./css/Layout.css"

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <>
        <div>
			<NavMenu />
            <div id="page-container">
                <NotificationContainer style={{marginTop: "100px",} } />
				<div id="content-wrap">
					{this.props.children}
				</div>
                <Footer />
            </div>
			
        </div>
			
		
    </>
    );
  }
}
