import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Mixpanel } from './Mixpanel';
import './css/Home.css';

export class Home extends Component {
  static displayName = Home.name;

    constructor(props) {
      super(props)
        this.state = {
            loading: true
        };

  }

  componentDidMount() {
      this._subscription = authService.subscribe(() => this.populateState());
      this.populateState();

  }

  componentWillUnmount() {
      authService.unsubscribe(this._subscription);
  }

  async populateState() {
      const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
      this.setState({
          isAuthenticated,
          providerId: user && user.sub
      });
      //this.getProfileInfo(user.sub);
      if (isAuthenticated && document.referrer.includes('login-callback')) {
          Mixpanel.identify(user.sub);
          Mixpanel.track("Login")
      }
      else if (isAuthenticated) {
          Mixpanel.track("Home")
      }
  }
    render() {
        return (
                <div>
                    <div className="container">
                        <div className="header page-title">
                            <h1>Online Work Log</h1>
                            <h5>Get started by selecting an option</h5>
                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-3 offset-sm-3 col-lg-2 offset-lg-4 homeColAlignRight homeBorderRight homeSelect">
                                <a href="/profile">
                                    <div className='icon-margin'>
                                        <svg className="svg-size" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 31.568 24.553">
                                            <path id="address-card" d="M28.938,32H2.631A2.631,2.631,0,0,0,0,34.631V53.923a2.631,2.631,0,0,0,2.631,2.631H28.938a2.631,2.631,0,0,0,2.631-2.631V34.631A2.631,2.631,0,0,0,28.938,32Zm0,21.923H2.631V34.631H28.938ZM11.4,44.277a3.508,3.508,0,1,0-3.508-3.508A3.511,3.511,0,0,0,11.4,44.277ZM6.489,51.292H16.31a1.149,1.149,0,0,0,1.228-1.052V49.187a3.451,3.451,0,0,0-3.683-3.157,13.232,13.232,0,0,1-2.455.438,13.04,13.04,0,0,1-2.455-.438,3.451,3.451,0,0,0-3.683,3.157V50.24A1.149,1.149,0,0,0,6.489,51.292ZM19.73,47.784h6.138a.44.44,0,0,0,.438-.438v-.877a.44.44,0,0,0-.438-.438H19.73a.44.44,0,0,0-.438.438v.877A.44.44,0,0,0,19.73,47.784Zm0-3.508h6.138a.44.44,0,0,0,.438-.438v-.877a.44.44,0,0,0-.438-.438H19.73a.44.44,0,0,0-.438.438v.877A.44.44,0,0,0,19.73,44.277Zm0-3.508h6.138a.44.44,0,0,0,.438-.438v-.877a.44.44,0,0,0-.438-.438H19.73a.44.44,0,0,0-.438.438v.877A.44.44,0,0,0,19.73,40.769Z" transform="translate(0 -32)" fill="#11294c" />
                                        </svg>
                                        <h4 className="svg-text">Profile</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-sm-3 col-lg-2 homeColAlignLeft homeSelect">
                                <a href="/assignments">
                                    <div className='icon-margin'>
                                        <svg className="svg-size" xmlns="http://www.w3.org/2000/svg" width="100" height="100" x="0" y="0" viewBox="0 0 510 510" >
                                            <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z     M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z" fill="#11294c" />
                                            <polygon points="267.75,127.5 229.5,127.5 229.5,280.5 362.1,362.1 382.5,328.95 267.75,260.1   " fill="#11294c" />
                                        </svg>
                                        <h4 className="svg-text">Timesheet Entry</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-3 offset-sm-3 col-lg-2 offset-lg-4 homeColAlignRight homeBorderTop homeBorderRight homeSelect">
                                <a href="https://www.consiliumstaffing.com/search-jobs" target="_blank">
                                    <div className='icon-margin'>
                                        <svg className="svg-size" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 25.152 25.157">
                                            <path id="search" d="M24.81,21.75l-4.9-4.9a1.178,1.178,0,0,0-.835-.344h-.8a10.214,10.214,0,1,0-1.769,1.769v.8a1.178,1.178,0,0,0,.344.835l4.9,4.9a1.174,1.174,0,0,0,1.665,0l1.39-1.39A1.185,1.185,0,0,0,24.81,21.75ZM10.219,16.507a6.289,6.289,0,1,1,6.289-6.289A6.285,6.285,0,0,1,10.219,16.507Z" fill="#11294c" />
                                        </svg>
                                        <h4 className="svg-text">Search Jobs</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-sm-3 col-lg-2  homeColAlignLeft homeBorderTop homeSelect">
                                <a href="/contact">
                                    <div className='icon-margin'>
                                        <svg className="svg-size" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 30.184 26.407">
                                            <path id="comment-dots" d="M8.426,42.374a1.886,1.886,0,1,0,1.886,1.886A1.884,1.884,0,0,0,8.426,42.374Zm6.6,0a1.886,1.886,0,1,0,1.886,1.886A1.884,1.884,0,0,0,15.027,42.374Zm6.6,0a1.886,1.886,0,1,0,1.886,1.886A1.884,1.884,0,0,0,21.629,42.374ZM15.027,32C6.693,32-.062,37.488-.062,44.261a10.836,10.836,0,0,0,3.118,7.445A14.958,14.958,0,0,1,.321,56.02a1.415,1.415,0,0,0,1.032,2.387,14.3,14.3,0,0,0,8.2-2.729,18.2,18.2,0,0,0,5.476.843c8.335,0,15.09-5.488,15.09-12.261S23.362,32,15.027,32Zm0,21.692a15.317,15.317,0,0,1-4.621-.713l-1.338-.424-1.149.813a12.675,12.675,0,0,1-3.389,1.709A14.785,14.785,0,0,0,5.7,52.707l.625-1.656L5.113,49.766a8.107,8.107,0,0,1-2.346-5.505c0-5.2,5.5-9.431,12.26-9.431s12.26,4.232,12.26,9.431S21.788,53.692,15.027,53.692Z" transform="translate(0.066 -32)" fill="#11294c" />
                                        </svg>
                                        <h4 className="svg-text">Contact Us</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            
        );
    }
}
