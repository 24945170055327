import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import moment from "moment";
import { SignatureModal } from './SignatureModal'
import { DnwModal } from './DnwModal'
import './css/TimesheetList.css';

//import './css/Assignments.css';

export class TimesheetList extends Component {
    static displayName = TimesheetList.name;


    constructor(props) {
        super(props);

        this.state = { aid: this.props.aid, facilityName: this.props.facilityName, timesheets: [], isAuthenticated: false, userName: '', loading: true };

    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();              
        
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.sub
        });
    }

  
    render() {
        var today = moment();
        var tomorrow = moment(today).add(-1, 'days');
        let payDate = this.getNextPayDate();
        //let dnwButton = this.tsData.status = 'Unsubmitted' ? <></> : <DnwModal markDNW={this.markDNW} />
        let content = this.state.loading ? <span> Loading timesheets . . . </span> : (
            <>
            <div className="list-container">
                {/*<div className="row provider-info">*/}
                {/*    <div className="col-3 offset-3">*/}
                {/*        <h5>Provider:<br />Dr.Van Nostrom</h5>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="row timesheet-list-header">
                    <div className="col">
                        <h6>Week</h6>
                    </div>
                    <div className="col">
                        <h6>Due Date</h6>
                    </div>
                    <div id="payment-date" className="col payment-date">
                        <h6>Payment Date</h6>
                    </div>
                    <div className="col">
                        <h6>Status</h6>
                    </div>
                    {/*<div id="payment-date" className="col payment-date">*/}
                    {/*    <h6>Quick Actions</h6>*/}
                    {/*</div>*/}
                </div>

                {this.state.timesheets.slice(0,6).map((timesheet, i) =>
                    <div className={i % 2 === 0 ? ("row provider-info evenRow timesheet-list") : ("row provider-info oddRow timesheet-list")}>
                        <div className="col">
                            <Link to={{ pathname: '/timesheet-entry/' + timesheet.id }}><span>{timesheet.firstDay}-{timesheet.lastDay}</span></Link>
                            {/*<a href={"/timesheet-entry?id=" + timesheet.id + "&facility=" + this.state.facilityName}>*/}
                            {/*    <span>{timesheet.firstDay}-{timesheet.lastDay}</span>*/}
                            {/*</a>*/}
                        </div>
                        <div className="col">
                            <span>{timesheet.dueDate}</span>
                        </div>
                        <div className="col payment-date">
                            {new Date(timesheet.originalPayDate) < payDate && timesheet.status == "Unsubmitted" ? <span>{payDate.toLocaleDateString().split("T")[0]} </span> : (<span>{new Date(timesheet.originalPayDate).toLocaleDateString()}</span>)}
                        </div>
                        <div className="col">
                            {new Date(timesheet.dueDate) < tomorrow && timesheet.status == "Unsubmitted" ? <span style={{ color: 'red', }}>{timesheet.status} - Late</span> : <span>{timesheet.status}</span>}
                        </div>
                        {/*{timesheet.status == "Unsubmitted" ? <div className="col"><DnwModal tsIndex={i} markDNW={this.markDNW} /></div> : <div id="payment-date" className="col"></div>}*/}

                    </div>
                )}
                </div>
            </>
            )

        const { isAuthenticated, userName } = this.state;        
        if (!isAuthenticated) {
            return (<div><span>not authenticated</span></div>);
        } else {
            if (this.state.timesheets.length === 0) {
                this.getTimesheets();
            }
            return (
                <>
                    {content}
                </>
            );
        }
    }

    async getTimesheets() {
        const token = await authService.getAccessToken();
        const tsResponse = await fetch("api/timesheet/ByAssignment/" + this.state.aid, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const tsData = await tsResponse.json();
        tsData.forEach(timesheet  => {
            timesheet.lastDay = new Date(timesheet.lastDay).toLocaleDateString();
            timesheet.firstDay = new Date(timesheet.firstDay).toLocaleDateString();
            timesheet.dueDate = new Date(timesheet.dueDate).toLocaleDateString();
        });
        //tsData.forEach(timesheet => timesheet.firstDay = new Date(timesheet.firstDay).toLocaleDateString());
        //tsData.forEach(timesheet => timesheet.dueDate = new Date(timesheet.dueDate).toLocaleDateString());
        this.setState({ timesheets: tsData, loading: false });
    }

    getNextPayDate() {
        var firstPayPeriod = new Date("01/13/2023");
        var thisFriday = new Date();
        thisFriday.setDate(thisFriday.getDate() + 5 - thisFriday.getDay());
        if (thisFriday % firstPayPeriod != 0) {
            thisFriday.setDate(thisFriday.getDate() + 7)
        }
      
        return thisFriday;


    }

    markDNW = index => async() => {

        var ts = { ...this.state.timesheets[index] };

        //delete ts.firstDay;
        //delete ts.lastDay;
        //delete ts.dueDate;


        ts.status = 'DNW';
        ts.editable = false;
        ts.dateSubmitted = new Date().toISOString().slice(0, 19);
        ts.firstDay =(new Date(ts.firstDay)).toISOString().slice(0, 19);
        ts.lastDay = (new Date(ts.lastDay)).toISOString().slice(0, 19);
        ts.dueDate = (new Date(ts.dueDate)).toISOString().slice(0, 19);
        ts.shiftworksUploaded = true;
        ts.PDFTimesheetCreated = true;
        ts.PDFTimesheetUploaded = true;
        

        const token = await authService.getAccessToken();

        const requestOptions = {
            method: 'PUT',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(ts),

        };

        var resp = await fetch('api/timesheet/' + ts.id, requestOptions);
        if (resp.status === 204) {
            var tempData = this.state.timesheets;
            tempData[index].status = ts.status;
            tempData[index].editable = ts.editable;
            tempData[index].dateSubmitted = ts.dateSubmitted;
            tempData[index].firstDay = ts.firstDay;
            tempData[index].lastDay = ts.lastDay
            tempData[index].dueDate = ts.dueDate
            this.setState({ timesheets: tempData });
            //Mixpanel.track("Timesheet Submit")
            window.location.reload(true);
        }

    }
}
