import React, { Component } from 'react';
import ReactModal from 'react-modal'
import "./css/Modal.css"
//import "./css/DnwModal.css"


export class DnwModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            lockScroll: false
        };
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal() {
        this.setState({ showModal: true, lockScroll: true });
        document.body.style.overflow = 'hidden';
    }

    handleCloseModal() {
        this.setState({ showModal: false, lockScroll: false });
        document.body.style.overflow = 'unset';
    }

    render() {
        let content =
            <>
            <button id="dnw" onClick={this.handleOpenModal}>Did Not Work</button>
            <ReactModal
                isOpen={this.state.showModal}
                contentLabel="Minimal Modal Example"
                className="content"
                overlayClassName="overlay"
                shouldFocusAfterRender={true}
            >
                <div className="modal-header">
                    <span>Did Not Work?</span>
                </div>
                <div className="modal-body">                            
                    <span>Are you sure you want to mark this week as Did Not Work?</span>
                    <br />
                            
                </div>
                <div className="modal-footer" >
                    <button onClick={this.props.markDNW(this.props.tsIndex)}>Yes</button>
                    <button onClick={this.handleCloseModal}>Cancel</button>
                </div>

            </ReactModal>
                </>;
        return (
            <div>
                {content}
            </div>
        );
    }
}