import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'
import './css/Footer.css'

const Footer = (props) => {
    return (
		<footer id="footer">
			<div className="" >
				<div className="row">
					<div className="col-md-3 offset-md-1 col-sm-12 footer-content"><img className="footer-logo" width="" src="pics/portal icon.png" /></div>
				</div>
				<div className="row footer-container">
					<div className="col-md-3 offset-md-1 col-sm-12 footer-content"><img className="name" src="pics/consilium-logo-white.png" /></div>
					<div className="col-md-4 col-sm-12 footer-content">
						<div className="footer_social_icons">
							6363 N. State Hwy 161<br />
							Suite 800<br />
							Irving, TX 75038<br />
							877-536-4696<br />
						
							<a className="footer_social_links" href="https://www.facebook.com/ConsiliumStaffing" target="_blank" ><FontAwesomeIcon icon={faFacebook} size="2x" /></a>
							<a className="footer_social_links" href="https://www.linkedin.com/company/consilium-staffing" target="_blank"><FontAwesomeIcon icon={faLinkedin} size="2x" /></a>
							<a className="footer_social_links" href="https://www.instagram.com/consiliumstaffing/" target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
							<a className="footer_social_links" href="http://twitter.com/consiliumlocums" target="_blank"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
						</div>
						
					
						
					</div>
					<div className="col-md-4 col-sm-12 footer-content">
						<p><a href="https://my.consiliumstaffing.com" className="footer-link">Home</a><br />
							<a href="https://my.consiliumstaffing.com/assignments" className="footer-link">Timesheets</a><br />
							<a href="https://consiliumstaffing.com/search-jobs" className="footer-link">Search Jobs</a><br />
							<a href="https://my.consiliumstaffing.com/contact" className="footer-link">Contact Us</a><br />
							<a href="https://consiliumstaffing.com/privacy-policy/" className="footer-link">Privacy Policy</a><br /></p>
					</div>
				</div>
			</div>
			<p class="small" style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faCopyright} /> 2023 Consilium Staffing, LLC | <a href="https://consiliumstaffing.com/" target="_blank" >Your Partner in Locum Tenens</a> | <a href="https://consiliumstaffing.com/privacy-policy/" target="_blank" >Privacy Policy</a></p>
		</footer>
	)
}
export default Footer