import { useState, useEffect } from "react";
import ExpenseFileLine from "./ExpenseFileLine"

export default function ExpenseFileList({ files, setFiles, timesheetId, token }) {

    //useEffect(() => {
    //    let ignore = false;
    //    setFiles([]);
    //    if (token !== '') {
    //        fetch("api/file/GetFiles/" + timesheetId, {
    //            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //        }).then(result => {
    //            if (!ignore) {
    //                return result.json();
    //            }
    //        }).then(data => {
    //            setFiles(data);
    //            return () => {
    //                ignore = true;
    //            }
    //        });

    //    }
    //}, [timesheetId, token]);
    const deleteFile = (file) => {
        let ignore = false;
        if (token !== '') {
            fetch("api/file/DeleteFile/" + file.id, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(result => {
                if (!ignore) {
                    setFiles([...files, {}]);
                    return result.json();
                }
            });

        }
        
    };

    const fileList = <>{Object.values(files).map((file, i) =>
        <ExpenseFileLine id="file-{i}" file={file} deleteFile={() => deleteFile(file)} />
    )}</>;

    const emptyList = <>
        <div className="expense-line">
            <div><span>No Files Uploaded. . . </span></div>
            <div></div>
        </div>
    </>;

    return <>
        <div className="expense-table-headers">
            <div><span className="expense-header-cells">File</span></div>
            <div><span className="expense-header-cells">Delete</span></div>
        </div>
        {files.length > 0 ? fileList : emptyList}
    </>
}
