import React, { useState } from 'react';
import ReactModal from 'react-modal'
import "./css/Modal.css"
import "./css/SignatureModal.css"


export default function ExpenseFileDeleteModal({ deleteFile, file }){

    const [showModal, setShowModal] = useState(false);
    const [lockScroll, setLockScroll] = useState(false);    

    const handleOpenModal = () => {
        setShowModal(true);
        setLockScroll(true);
        document.body.style.overflow = 'hidden';
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setLockScroll(false);
        document.body.style.overflow = 'unset';
    }

    return (
        <div>
            <img className="delete-button2" style={{ float: "right", margin: "10px 20px 10px 0px", alignContent: "center", }} src="../pics/x-icon.png" height="25px" width="25px" title="Delete Expense" alt="icon to delete expense" onClick={handleOpenModal} />
                    <ReactModal
                        isOpen={showModal}
                        contentLabel="Minimal Modal Example"
                        className="content"
                        overlayClassName="overlay"
                        shouldFocusAfterRender={true}
                        appElement={document.getElementById('root') || undefined}
                    >
                        <div className="modal-header">
                            <span>Delete File?</span>
                        </div>
                        <div className="modal-body">
                            {/*error*/}
                         <span>Are you sure you want to delete {file.name}</span>


                        </div>
                        <div className="modal-footer" >
                    <button onClick={() => { deleteFile(file); handleCloseModal(); }}>Yes</button>
                            <button onClick={handleCloseModal}>No</button>
                        </div>

                    </ReactModal>
        </div>
    );
    
}