import React, { Component } from 'react';
import { TimesheetLine3 } from './TimesheetLine3';
import authService from './api-authorization/AuthorizeService'
import moment from "moment"
import "./css/TimesheetDate.css"

export class TimesheetDate3 extends Component {
    static displayName = TimesheetDate3.name;

    constructor(props) {
        super(props);
        this.state = {lines: props.lines, addLine: props.addLine, error:false, first: true, loading: true };
    }

    deleteLine = (id) => {
        let newLines = this.state.lines;
        newLines.forEach((line, i) => {
            if (line['id'] === id) {
                newLines.splice(i, 1);
            }
        })
        this.props.updateDay(this.props.index, newLines);
        this.setState({ lines: newLines });
    }

    setError = (value) => {
        this.setState({ error: value });
    }

    addLine = async (date) => {

        let line = { date: date, timesheetId: this.state.lines[0].timesheetId, totalHours: 0, updated: false, extraShift: true }
        const token2 = await authService.getAccessToken();
        const requestOptions = {
            method: 'POST',
            headers: !token2 ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token2}` },
            body: JSON.stringify(line),

        };
        var r = await fetch('api/timesheetline', requestOptions);
        var data = await r.json();
        line = data;
        var newLines = this.state.lines;
        newLines.push(line);
        this.setState({ lines: newLines })
        //this.props.lineUpdate(line);
        this.forceUpdate();
    }


    render() {
        return (
            <>                
                {this.state.lines.map((line, i) =>
                    <>  
                        <div className="timesheet-shift">
                            <div className='timesheet-cells'>
                                <TimesheetLine3 key={line.Id} id={line.id} date={line.date} line={line} editable={this.props.editable} addLine={this.addLine} deleteLine={this.deleteLine} updateError={this.setError} error={line.error} evenOdd={i % 2} order={i} updateLine={this.updateLine} updateTotalTimesheetHours={this.props.updateTotalTimesheetHours} />
                            </div>
                            <div className={this.state.error ? "show-error offset-2" : "hide-error"}>Total Hours cannot be negative. Check that your mealtime was entered correctly.</div>
                        </div>
                        </>
                    )}

            </>
        )
    }

    



}