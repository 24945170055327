import authService from './api-authorization/AuthorizeService';
import "./css/Expenses.css"

export default function ExpenseDownloadLink({ file }) {
    const getFile = async () => {
        const token = await authService.getAccessToken();
        if (token !== '') {
            var response = fetch("api/file/GetFile/"+file.timesheetId+"/"+file.name+"/", {
                headers: !token ? {} : { 'authorization': `bearer ${token}`, 'Content-Type':'application/pdf' }
            }).then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.name;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
        };
    }
    return( <>
        <button className="download-button" onClick={getFile}>{file.name}</button>
    </>)
    
}