import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import authService from './api-authorization/AuthorizeService';
import "./css/DragDrop.css"

const fileTypes = ["JPG", "PNG", "PDF", "DOC", "DOCX"];

function DragDrop({ files, setFiles, timesheetId}) {

    const [file, setFile] = useState(null);
    const [spinner, setSpinner] = useState(false);

    const handleChange = async (file) => {        
        const formData = new FormData();
        formData.append("file", file);
        formData.append("timesheetId", timesheetId)
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            body: formData
        };
        try {
            setSpinner(true);
            const response = await fetch('api/file/upload', requestOptions)
                .then(response => {
                    if (!response.ok) {
                        setSpinner(false);
                        response.text().then(text => {
                            throw new Error(`Request rejected with status ${response.status} and message ${text}`);
                        })
                            .catch(error =>
                                console.log(`Issue Uploading File: ${error}`)
                            );
                    }
                    else {
                        setSpinner(false);
                        return response.json();
                    }
                })
                .then(data => {
                    return true;
                })
            if (response) {
                setFiles([response]);
                setFile(response);
            }
            console.log(response);
        }
        catch(e){
            console.log(e);
        }
        
    }; 
    const loading = <>
        <FileUploader handleChange={handleChange} name="file" types={fileTypes} fileOrFiles={file} classes="drop_area" disabled={true} />
        <img className="spinner" src="./pics/loading-spinner.gif" width="50px" height="50px" />
    </>
    return (
        <div className="upload-field expense-upload">
            {spinner ? loading : <FileUploader handleChange={handleChange} name="file" types={fileTypes} fileOrFiles={file} classes="drop_area" disabled={false} />}
        </div>
    );
}

export default DragDrop;