import React from 'react';
import { TimesheetList } from "./TimesheetList";
import { useParams, useSearchParams } from 'react-router-dom';

const GetTimesheets = (props) => {
    const id = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <>
            <TimesheetList key={searchParams.get("aid")} aid={searchParams.get("aid")} facilityName={searchParams.get("fn")} />
        </>
    )
}
export default GetTimesheets;
